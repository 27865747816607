<template>
  <button
    v-if="!href"
    type="button"
    :class="{
      button: true,
      tinted,
      yellow: color === 'yellow',
      green: color === 'green',
      blue: color === 'blue',
      red: color === 'red',
      pending: pending,
      disabled: disabled,
    }"
  >
    <div style="flex: 1; text-align: center">{{ text }}</div>
  </button>
  <a
    v-else
    type="button"
    :href="href"
    target="_blank"
    :class="{
      tinted,
      yellow: color === 'yellow',
      green: color === 'green',
      blue: color === 'blue',
      red: color === 'red',
      disabled: disabled,
      pending: pending,
    }"
  >
    <div style="flex: 1; text-align: center">{{ text }}</div>
  </a>
</template>

<script lang="ts" setup>
import { computed } from "vue";

type Color = "yellow" | "green" | "blue" | "red";
declare var TINT_COLOR: string;

defineProps<{
  href?: string;
  text: string;
  color: Color;
  pending?: boolean;
  disabled?: boolean;
}>();

const tinted = computed(() => TINT_COLOR !== "#0069FF");
</script>

<style lang="scss" scoped>
[type="button"] {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  flex: 1 1 0%;
  align-items: center;
  border-radius: 9999px;
  border-width: 1px;
  border-color: transparent;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

[type="button"].pending {
  opacity: 0.5;
}

[type="button"].yellow {
  background-color: #d97706 !important;

  &:hover {
    background-color: #b45309 !important;
  }
}

[type="button"].green {
  background-color: #059669 !important;

  &:hover {
    background-color: #047857 !important;
  }
}

[type="button"].blue {
  background-color: #2563eb;

  &:hover {
    background-color: #1d4ed8;
  }
}

[type="button"].red {
  background-color: #dc2626 !important;

  &:hover {
    background-color: #b91c1c !important;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
